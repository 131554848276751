import { useEffect, useState } from 'react';
import axios, { AxiosError, AxiosResponse, Method } from 'axios';
import Config from '../config';
import { useMsal } from '@azure/msal-react';
import { AccountInfo } from '@azure/msal-browser';
import { apiRequest } from '../config/authConfig';
import { GenericObject, isString } from './extensions';
import { handleSignOut } from '../components/auth';
import { handleDates } from './isoDate';
import { isEqual, join } from 'lodash';
import { usePrevious } from './usePrevious';
import { GUID } from '../interfaces/guid';
import { IDocument } from '../interfaces/document';
import { useMsalAccount } from './userHooks';
/* eslint-disable */

const qs = require('qs');

axios.defaults.baseURL = Config.apiURL;
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['x-originator'] = 'nya-portal';
axios.interceptors.response.use((response) => {
  handleDates(response.data); //Json uses strings for dates not ISO dates so we convert them on axios response
  return response;
});

interface useApiProps<T> {
  url: string;
  method?: Method;
  body?: T;
  withValidation?: boolean;
  headers?: Record<string, string>;
}

export interface useApiReturn<TResponse, TRequest> {
  data: TResponse | null;
  error: string | null;
  code: number | null;
  loading: boolean;
  fetchData: () => void;
  postData: (data?: TRequest) => void;
}

function useApi<TResponse, TRequest = void>({
  url,
  method = 'GET',
  body,
  withValidation = true,
  headers
}: useApiProps<TRequest>): useApiReturn<TResponse, TRequest> {
  // const [useValidation] = useState(withValidation);
  const [code, setCode] = useState<number | null>(null);
  const [data, setData] = useState<TResponse | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const prevBody = usePrevious(body);
  const prevValidation = usePrevious(withValidation);

  const controller = new AbortController();
  const signal = controller.signal;

  const { instance, account, authority } = useMsalAccount();

  const request = {
    ...apiRequest,
    account: account,
    authority: authority
  };

  const validateToken = (callback: (token: string) => void) => {
    instance
      .acquireTokenSilent(request)
      .then((response) => {
        callback(response.accessToken);
      })
      .catch((e) => {
        console.error('Silent request for token failed.', { error: e });
        instance.logoutRedirect();
      });
  };

  const fetchData = () => {
    if (withValidation) validateToken((token) => callApi(token));
    else callApi();
  };

  const postData = (data?: TRequest) => {
    if (withValidation) validateToken((token) => callApi(token, data));
    else callApi(undefined, data);
  };

  const callApi = (token?: string, data?: TRequest) => {
    setCode(null);
    setLoading(true);
    setError(null);
    axios
      .request({
        url: url,
        method: method,
        headers: JSON.parse(
          JSON.stringify({
            Authorization: `Bearer ${token}`,
            ...headers
          })
        ),
        data: data ?? body ?? {},
        params: method === 'GET' || method === 'get' ? body ?? {} : '',
        paramsSerializer: (params) => {
          if (isString(params))
            return qs.stringify(JSON.parse(params), { allowDots: true });
          return qs.stringify(params, { allowDots: true });
        },
        signal: signal
      })
      .then((res: AxiosResponse) => {
        //console.log(res.data as TResponse);
        setData(res.data as TResponse);
        setCode(res.status);
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 401) {
          // Unauthorised; API says no, so log the user out
          handleSignOut(instance);
        } else {
          if (err.response?.status) setCode(err.response!.status);
          let errorMessage = err.message;
          if (err.response?.data && isString(err.response.data)) {
            errorMessage = err.response.data;
          } else if (
            typeof err.response?.data === 'object' &&
            err.response.data !== null
          ) {
            if ('errors' in err.response.data) {
              errorMessage = join(
                (err.response.data as any).errors.map(
                  (e: GenericObject) => e.message
                ),
                ', '
              );
            } else if ('Message' in err.response.data)
              errorMessage = (err.response.data as any).Message;
            else if ('detail' in err.response.data)
              errorMessage = (err.response.data as any).detail;
            else if ('title' in err.response.data)
              errorMessage = (err.response.data as any).title;
            else
              errorMessage = join((err.response.data as any).errors.id, ', ');
          }
          setError(errorMessage);
          if (err.response?.status) setCode(err.response?.status);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (method === 'GET' || method === 'get') {
      fetchData();
    }
    /* return function cleanup() {
                                                                                              controller.abort();
                                                                                            }; */
  }, []);

  useEffect(() => {
    if (
      (method === 'GET' || method === 'get') &&
      (!isEqual(prevBody, body) || !isEqual(prevValidation, withValidation))
    ) {
      fetchData();
    }
  }, [prevBody, body, withValidation]);

  return { data, error, code, loading, fetchData, postData };
}

export const useApiDocumentUpload = () => {
  const [code, setCode] = useState<number | null>(null);
  const [data, setData] = useState<{
    uploadedDocuments: IDocument[];
    failedDocuments: string[];
  }>();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(0);

  const { instance, account, authority } = useMsalAccount();
  const request = {
    ...apiRequest,
    account: account,
    authority: authority
  };

  const validateToken = (callback: (token: string) => void) => {
    instance
      .acquireTokenSilent(request)
      .then((response) => {
        callback(response.accessToken);
      })
      .catch((e) => {
        console.error('Silent request for token failed.', { error: e });
        instance.logoutRedirect();
      });
  };

  const post = (data: File[], parentId: GUID) => {
    validateToken((token) => callApi(token, data, parentId));
  };

  const callApi = (token: string, data: File[], parentId: GUID) => {
    setCode(null);
    setLoading(true);
    setError(null);

    const formData = new FormData();
    data.forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });

    axios
      .request({
        url: `documents/${parentId}`,
        method: 'POST',
        headers: JSON.parse(
          JSON.stringify({
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          })
        ),
        data: formData,
        onUploadProgress: (progress) => {
          setLoaded(progress.total / progress.loaded);
        }
      })
      .then((res: AxiosResponse) => {
        setCode(res.status);
        setData(res.data);
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 401) {
          // Unauthorised; API says no, so log the user out
          handleSignOut(instance);
        } else {
          let errorMessage = err.message;
          if (err.response?.data && isString(err.response.data)) {
            errorMessage = err.response.data;
          } else if (
            typeof err.response?.data === 'object' &&
            err.response.data !== null
          ) {
            if ('Message' in err.response.data)
              errorMessage = (err.response.data as any).Message;
            else if ('detail' in err.response.data)
              errorMessage = (err.response.data as any).detail;
            else if ('title' in err.response.data)
              errorMessage = (err.response.data as any).title;
            else
              errorMessage = join((err.response.data as any).errors.id, ', ');
          }
          setError(errorMessage);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return { post, data, code, loading, loaded, error };
};

export const useApiDocumentDelete = () => {
  const [code, setCode] = useState<number | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const { instance, accounts } = useMsal();
  const request = {
    ...apiRequest,
    account: instance.getActiveAccount() as AccountInfo
  };

  const validateToken = (callback: (token: string) => void) => {
    instance
      .acquireTokenSilent(request)
      .then((response) => {
        callback(response.accessToken);
      })
      .catch((e) => {
        console.error('Silent request for token failed.', { error: e });
        instance.logoutRedirect();
      });
  };

  const deleteDocument = (id: GUID) => {
    validateToken((token) => callApi(token, id));
  };

  const callApi = (token: string, id: GUID) => {
    setCode(null);
    setLoading(true);
    setError(null);

    axios
      .request({
        url: `documents/${id}`,
        method: 'DELETE',
        headers: JSON.parse(
          JSON.stringify({
            Authorization: `Bearer ${token}`
          })
        )
      })
      .then((res: AxiosResponse) => {
        setCode(res.status);
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 401) {
          // Unauthorised; API says no, so log the user out
          handleSignOut(instance);
        } else {
          let errorMessage = err.message;
          if (err.response?.data && isString(err.response.data)) {
            errorMessage = err.response.data;
          } else if (
            typeof err.response?.data === 'object' &&
            err.response.data !== null
          ) {
            if ('Message' in err.response.data)
              errorMessage = (err.response.data as any).Message;
            else if ('detail' in err.response.data)
              errorMessage = (err.response.data as any).detail;
            else if ('title' in err.response.data)
              errorMessage = (err.response.data as any).title;
            else
              errorMessage = join((err.response.data as any).errors.id, ', ');
          }
          setError(errorMessage);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return { deleteDocument, code, loading, error };
};

interface DownloadInfo {
  progress: number;
  completed: boolean;
  error?: string;
  total: number;
  loaded: number;
  shown: boolean;
}

export const useApiDocumentGet = () => {
  const [code, setCode] = useState<number | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [downloadInfo, setDownloadInfo] = useState<DownloadInfo>({
    progress: 0,
    completed: false,
    total: 0,
    loaded: 0,
    shown: true
  });

  const { instance, account, authority } = useMsalAccount();
  const request = {
    ...apiRequest,
    account: account,
    authority: authority
  };

  const validateToken = (callback: (token: string) => void) => {
    instance
      .acquireTokenSilent(request)
      .then((response) => {
        callback(response.accessToken);
      })
      .catch((e) => {
        console.error('Silent request for token failed.', { error: e });
        instance.logoutRedirect();
      });
  };

  const getDocument = (id: GUID, fileName: string) => {
    validateToken((token) => callApi(token, id, fileName));
  };

  const callApi = (token: string, id: GUID, fileName: string) => {
    setCode(null);
    setLoading(true);
    setError(null);

    axios
      .request({
        url: `documents/${id}`,
        method: 'GET',
        headers: JSON.parse(
          JSON.stringify({
            Authorization: `Bearer ${token}`
          })
        ),
        onDownloadProgress: (progressEvent: any) => {
          const { loaded, total } = progressEvent;
          setDownloadInfo((info) => ({
            ...info,
            progress: Math.floor((loaded * 100) / total),
            loaded,
            total
          }));
        },
        responseType: 'blob'
      })
      .then((res: AxiosResponse) => {
        const url = window.URL.createObjectURL(
          new Blob([res.data], {
            type: res.headers['content-type']
          })
        );
        // NEW CODE, Opens in new tab instead.
        window.open(url, '_blank');
        setTimeout(() => URL.revokeObjectURL(url), 1000);

        // OLD CODE, Create download for the file instead.

        // const link = document.createElement('a');
        // link.href = url;
        // link.setAttribute('download', fileName);
        // document.body.appendChild(link);
        // link.click();
        setCode(res.status);
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 401) {
          // Unauthorised; API says no, so log the user out
          handleSignOut(instance);
        } else {
          let errorMessage = err.message;
          if (err.response?.data && isString(err.response.data)) {
            errorMessage = err.response.data;
          } else if (
            typeof err.response?.data === 'object' &&
            err.response.data !== null
          ) {
            if ('Message' in err.response.data)
              errorMessage = (err.response.data as any).Message;
            else if ('detail' in err.response.data)
              errorMessage = (err.response.data as any).detail;
            else if ('title' in err.response.data)
              errorMessage = (err.response.data as any).title;
            else
              errorMessage = join((err.response.data as any).errors.id, ', ');
          }
          setError(errorMessage);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return { getDocument, code, loading, error, downloadInfo };
};

export const useApiDownload = () => {
  const [code, setCode] = useState<number | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [downloadInfo, setDownloadInfo] = useState<DownloadInfo>({
    progress: 0,
    completed: false,
    total: 0,
    loaded: 0,
    shown: true
  });

  const { instance, accounts } = useMsal();
  const request = {
    ...apiRequest,
    account: instance.getActiveAccount() as AccountInfo
  };

  const validateToken = (callback: (token: string) => void) => {
    instance
      .acquireTokenSilent(request)
      .then((response) => {
        callback(response.accessToken);
      })
      .catch((e) => {
        console.error('Silent request for token failed.', { error: e });
        instance.logoutRedirect();
      });
  };

  const getFile = (url: string, fileName: string) => {
    validateToken((token) => callApi(token, url, fileName));
  };

  const callApi = (token: string, url: string, fileName: string) => {
    setCode(null);
    setLoading(true);
    setError(null);

    axios
      .request({
        url: url,
        method: 'GET',
        headers: JSON.parse(
          JSON.stringify({
            Authorization: `Bearer ${token}`
          })
        ),
        onDownloadProgress: (progressEvent: any) => {
          const { loaded, total } = progressEvent;

          setDownloadInfo((info) => ({
            ...info,
            progress: Math.floor((loaded * 100) / total),
            loaded,
            total
          }));
        },
        responseType: 'blob'
      })
      .then((res: AxiosResponse) => {
        const url = window.URL.createObjectURL(
          new Blob([res.data], {
            type: res.headers['content-type']
          })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        setCode(res.status);
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 401) {
          // Unauthorised; API says no, so log the user out
          handleSignOut(instance);
        } else {
          let errorMessage = err.message;
          if (err.response?.data && isString(err.response.data)) {
            errorMessage = err.response.data;
          } else if (
            typeof err.response?.data === 'object' &&
            err.response.data !== null
          ) {
            if ('Message' in err.response.data)
              errorMessage = (err.response.data as any).Message;
            else if ('detail' in err.response.data)
              errorMessage = (err.response.data as any).detail;
            else if ('title' in err.response.data)
              errorMessage = (err.response.data as any).title;
            else if ('errors' in err.response.data)
              errorMessage = join((err.response.data as any).errors.id, ', ');
          }
          setError(errorMessage);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return { getFile, code, loading, error, downloadInfo };
};

export default useApi;
